import React from "react";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import "../../node_modules/react-vis/dist/style.css";
import "react-virtualized/styles.css";

import {
  XYPlot,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  LineSeries,
} from "react-vis";

const myFormatter = (value) => {
  return <div>{value}</div>;
};

export default function Index(props) {
  const { miningData } = props;

  const miners = [
    miningData.kryptex,
    miningData.betterhash,
    miningData.cudoos,
    miningData.nhml,
    miningData.cudo,
    miningData.nhos,
    miningData.nhqm,
  ];

  const data = miningData.res.map((x, i) => ({
    title: x.miner,
    key: i,
    data: miners[i].map((y, j) => ({
      x: new Date(miningData.updates[j]),
      y,
    })),
  }));

  const colorPaletteDict = {
    Kryptex: "#ed232e", //
    NHOS: "#4f772d",
    NHQM: "#ff772d",
    NHM: "#fb8500",
    Betterhash: "#003049",
    CUDO: "#8338ec",
    CudoOS: "#e09f3e",
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "400px",
        maxWidth: "740px",
        margin: "0 auto",
      }}
    >
      <DiscreteColorLegend
        orientation="horizontal"
        items={data.map((x) => ({
          color: colorPaletteDict[x.title],
          title: x.title,
        }))}
      />
      <AutoSizer>
        {({ height, width }) => (
          <div>
            <XYPlot
              margin={{ left: 50, right: 50 }}
              xType="time"
              width={width - 0}
              height={height - 100}
            >
              <XAxis
                tickValues={miningData.updates.map((x) => new Date(x))}
                tickFormat={(v) => v.toISOString().split("T")[0].substr(2)}
              />
              <YAxis />
              {data.map((props) => (
                <LineSeries {...props} color={colorPaletteDict[props.title]} />
              ))}
            </XYPlot>
          </div>
        )}
      </AutoSizer>
    </div>
  );
}
