import styled from "styled-components";

const Wrap2 = styled.div((props) => ({
  marginTop: 24,
}));

const Text = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 16,
}));

const Label = styled.div((props) => ({
  marginLeft: 2,
}));

const Line = styled.div((props) => ({
  // width: 400,
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  height: 50,
  marginBottom: 1,
  paddingLeft: 24,
  "@media (max-width: 500px)": {
    width: "calc(100% - 24px)",
  },
}));

export default function TabGroup(props) {
  const { active } = props;

  return (
    <>
      {active === "AMD" ? (
        <Wrap2>
          <Line>
            <Text>OS:</Text>
            <Label>Windows 10</Label>
          </Line>
          <Line>
            <Text>CPU:</Text>
            <Label>AMD Ryzen 5 5600x</Label>
          </Line>
          <Line>
            <Text>RAM:</Text>
            <Label>8GB</Label>
          </Line>
          <Line>
            <Text>GPU 1:</Text>
            <Label>AMD RX 5700xt</Label>
          </Line>
          <Line>
            <Text>GPU 2:</Text>
            <Label>AMD RX 6800</Label>
          </Line>
        </Wrap2>
      ) : (
        <Wrap2>
          <Line>
            <Text>OS:</Text>
            <Label>Windows 10</Label>
          </Line>
          <Line>
            <Text>CPU:</Text>
            <Label>AMD Ryzen 5 5600x</Label>
          </Line>
          <Line>
            <Text>RAM:</Text>
            <Label>8GB</Label>
          </Line>
          <Line>
            <Text>GPU 1:</Text>
            <Label>RTX 2060</Label>
          </Line>
          <Line>
            <Text>GPU 2:</Text>
            <Label>RTX 3060 Ti</Label>
          </Line>
        </Wrap2>
      )}
    </>
  );
}
