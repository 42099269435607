import { useEffect, useState } from "react";
import styled from "styled-components";

const getData = () => {
  // curl -H "X-CMC_PRO_API_KEY: da151411-716a-4f10-bced-d4d628b7ceaf" -H "Accept: application/json" https://pro-api.coinmarketcap.com/v1/global-metrics/quotes/latest
  fetch("https://api.coingecko.com/api/v3/global", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      console.log(myJson);
    });
};

export default function Header() {
  const data = 3;
  const [state, setState] = useState(null);
  // getData();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://api.coingecko.com/api/v3/global", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // const response = await fetch(`https://swapi.dev/api/people/${props.id}/`);
      const newData = await response.json();
      console.log(newData);
      setState(newData);
    };

    fetchData();
  }, []);

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <Logo src="../../logo.png" />
          <Nav>
            {/* <LinkWrapper> */}
            {/*   <Dot /> */}
            {/*   <Link>Live Streaming Mining</Link> */}
            {/* </LinkWrapper> */}
            <a style={{ textDecoration: "none" }} href={"/faq.html"}>
              <Link>FAQ</Link>
            </a>
            <a style={{ textDecoration: "none" }} href={"#contact"}>
              <Link>Contact</Link>
            </a>
            <a style={{ textDecoration: "none" }} href={"#donate"}>
              <Button>DONATE</Button>
            </a>
          </Nav>
          <Nav2>
            <a style={{ textDecoration: "none" }} href={"#donate"}>
              <Button>DONATE</Button>
            </a>
          </Nav2>
        </InnerWrapper>
      </Wrapper>
      <Wrapper>
        <InnerWrapper2>
          <FlexWrap>
            <Text>Cryptocurrencies:</Text>
            <BlueText>
              {state && "data" in state
                ? state.data.active_cryptocurrencies
                : 0}
            </BlueText>
          </FlexWrap>
          <GrayDot />

          <FlexWrap>
            <Text>Markets:</Text>
            <BlueText>
              {state && "data" in state ? state.data.markets : 0}
            </BlueText>
          </FlexWrap>
          <GrayDot />

          <FlexWrap>
            <Text>Market Cap:</Text>
            <BlueText>
              $
              {state && "data" in state
                ? parseFloat(state.data.total_market_cap.usd).toFixed(0)
                : 0}
            </BlueText>
          </FlexWrap>
          <GrayDot />

          <FlexWrap>
            <Text>24h Vol:</Text>
            <BlueText>
              $
              {state && "data" in state
                ? parseFloat(state.data.total_volume.usd).toFixed(0)
                : 0}
            </BlueText>
          </FlexWrap>
          <GrayDot />

          <FlexWrap>
            <Text>BTC Dominance</Text>
            <BlueText>
              {state && "data" in state
                ? parseFloat(state.data.market_cap_percentage.btc).toFixed(2)
                : 0}
              %
            </BlueText>
          </FlexWrap>
        </InnerWrapper2>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div((props) => ({
  width: "100%",
  background: "#fff",
  marginBottom: 1,
}));

const InnerWrapper = styled.div((props) => ({
  margin: "0 auto",
  width: "calc(100% - 200px)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 72,
  maxWidth: 1440,
  "@media (max-width: 1200px)": {
    width: "calc(100% - 80px)",
  },

  "@media (max-width: 500px)": {
    width: "calc(100% - 40px)",
  },
}));

const InnerWrapper2 = styled.div((props) => ({
  margin: "0 auto",
  width: "calc(100% - 200px)",
  display: "flex",
  alignItems: "center",
  height: 36,
  maxWidth: 1440,
  "@media (max-width: 1200px)": {
    width: "calc(100% - 80px)",
  },
  "@media (max-width: 800px)": {
    flexDirection: "column",
    height: "auto",
    alignItems: "flex-start",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  "@media (max-width: 500px)": {
    width: "calc(100% - 40px)",
  },
}));

const Nav = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 800px)": {
    display: "none",
  },
}));

const Nav2 = styled.div((props) => ({
  display: "none",
  "@media (max-width: 800px)": {
    display: "block",
  },
}));

const LinkWrapper = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Dot = styled.div((props) => ({
  width: 8,
  height: 8,
  borderRadius: 4,
  background: "#cc0000",
  marginRight: 6,
  marginBottom: -2,
}));

const Link = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 14,
  marginRight: 34,
  cursor: "pointer",
  transition: "0.2s",
  ":hover": {
    color: "#000",
  },
}));

const Button = styled.div((props) => ({
  width: 120,
  height: 39,
  borderRadius: "24px",
  border: "2px solid #0d62fe",
  color: "#0d62fe",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: 1.1,
  transition: "0.2s",
  cursor: "pointer",
  ":hover": {
    background: "#0d62fe",
    color: "#fff",
  },
}));

const Logo = styled.img((props) => ({
  width: 220,
  "@media (max-width: 410px)": {
    width: 140,
  },
}));

const Text = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 12,
}));

const BlueText = styled.div((props) => ({
  color: "#0d62fe",
  fontSize: 12,
  marginLeft: 4,
}));

const FlexWrap = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  "@media (max-width: 800px)": {
    marginBottom: 4,
    marginTop: 4,
  },
}));

const GrayDot = styled.div((props) => ({
  width: 2,
  height: 2,
  borderRadius: 1,
  background: "#7a7a7a",
  margin: "0px 5px",
  "@media (max-width: 800px)": {
    display: "none",
  },
}));
