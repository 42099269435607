import styled from "styled-components";

const Wrap6 = styled.div((props) => ({
  marginTop: 24,
  marginBottom: 24,
}));

const QRCode = styled.img((props) => ({
  width: 200,
}));

const Text = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 16,
}));

const Label = styled.div((props) => ({
  marginLeft: 2,
  fontSize: "14px",
}));

const Line = styled.div((props) => ({
  // width: 400,
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  height: 50,
  marginBottom: 1,
  paddingLeft: 24,
  "@media (max-width: 500px)": {
    width: "calc(100% - 24px)",
  },
}));

export default function TabGroup(props) {
  const { active } = props;

  return (
    <>
      {active === "ETH" ? (
        <>
          <Line>
            <Text>ETH:</Text>
            <Label>0x7119233A7AF68D9Af155732FB30e10ee4407f489</Label>
          </Line>
          <Wrap6>
            <Text>Ethereum (ETH) QR Code</Text>
          </Wrap6>{" "}
          <QRCode src="../../qreth.png" />
        </>
      ) : active === "LTC" ? (
        <>
          <Line>
            <Text>LTC:</Text>
            <Label>LUwSnojLMUjDwaYqhy1wAax3PRfVdzjRoD</Label>
          </Line>
          <Wrap6>
            <Text>Litcoin (LTC) QR Code</Text>
          </Wrap6>{" "}
          <QRCode src="../../qrltc.png" />
        </>
      ) : (
        <>
          <Line>
            <Text>BTC:</Text>
            <Label>14GmpkcTuvG6m26xyTAyVP9twJHyEVEGy6</Label>
          </Line>
          <Wrap6>
            <Text>Bitcoin (BTC) QR Code</Text>
          </Wrap6>{" "}
          <QRCode src="../../qrbtc.png" />
        </>
      )}
    </>
  );
}
