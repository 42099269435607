import React, { useEffect, useState } from "react";
import styled from "styled-components";

const getWidth = () =>
  document.getElementById("progressId").getBoundingClientRect().width;

const ProgrssBar = ({ value }) => {
  let [width, setWidth] = useState(1);

  useEffect(() => {
    setWidth(getWidth());
    const resizeListener = () => {
      setWidth(getWidth());
    };

    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <Wrapper id="progressId">
      <Bar width={(width * value) / 100}>
        <Text>{value}%</Text>
      </Bar>
    </Wrapper>
  );
};

export default ProgrssBar;

const Wrapper = styled.div((props) => ({
  width: "100%",
  height: 20,
  background: "rgba(196, 196, 196, 0.5)",
  position: "relative",
  marginTop: 25,
  marginBottom: 10,
}));

const Text = styled.div((props) => ({
  textAlign: "center",
  position: "relative",
  top: "3px",
  color: "white",
  fontWeight: "bold",
}));

const Bar = styled.div((props) => ({
  background: "#0d62fe",
  position: "absolute",
  left: 0,
  height: 30,
  top: -5,
  transition: "0.4s",
  width: props.width,
  borderRadius: 3,
  textAlign: "center",
}));
