import _ from "lodash";

const updates = [
  "2021-07-01", //
  "2021-08-09", //
  "2021-09-20", //
  "2021-11-02", //
  "2021-12-06", //
  "2022-01-10", //
  "2022-02-11", //
  "2022-03-14", //
  "2022-06-07", //
  "2022-07-18", //
  "2022-08-16", //
  "2022-09-13", //
  "2022-11-14", //
  "2022-12-05", //
  "2023-01-03", //
  "2023-01-30", //
  "2023-03-13", //
  "2023-04-17", //
];

const nhml_data = [
  0.0, //
  0.00676172,
  0.00606166,
  0.00555154,
  0.00427034,
  0.0046478,
  0.00333523,
  0.00209211,
  0.00784258,
  0.00345937,
  0.00334289,
  0.00327543,
  0.00165099,
  0.00044068,
  0.00081224,
  0.00066911,
  0.00054192,
  0.00065841,
];

const nhos_data = [
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.00691997,
  0.00347791,
  0.0033624,
  0.00329126,
  0.00151732,
  0.00026817,
  0.00042267,
  0.00024442,
  0.00025142,
  0.00041471,
];

const nhqm_data = [
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.0, //
  0.00848285,
  0.00358934,
  0.00338671,
  0.00331774,
  0.00138385,
  0.00036908,
  0.00064444,
  0.00054042,
  0.00062751,
  0.00042903,
];

const cudo_data = [
  0.0, //
  0.00482614,
  0.00433373,
  0.00435948,
  0.00348637,
  0.00406307,
  0.0031873,
  0.00300477,
  0.00781726,
  0.00295661,
  0.00285612,
  0.00280846,
  0.00144131,
  0.00050428,
  0.00066165,
  0.00055488,
  0.00076463,
  0.00049024,
];

const cudoos_data = [
  0.0, //
  0.00461023,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
];

const betterhash_data = [
  0.0, //
  0.0063165,
  0.00638634,
  0.00524401,
  0.00412596,
  0.00415676,
  0.0029727,
  0.00285372,
  0.00750756,
  0.00309248,
  0.0028683,
  0.00294722,
  0.00132816,
  0.00037514,
  0.00045503,
  0.0003979,
  0.00075155,
  0.00060475,
];

const kryptex_data = [
  0.0, //
  0.00290459,
  0.00319988,
  0.00263348,
  0.0022246,
  0.00336576,
  0.00300739,
  0.00271864,
  0.00714884,
  0.00288847,
  0.00287133,
  0.00299012,
  0.0016354,
  0.00048897,
  0.00069773,
  0.00066118,
  0.0008465,
  0.00065387,
];

const period =
  (new Date(updates.slice(-1)[0]).getTime() -
    new Date(updates.slice(-2, -1)[0]).getTime()) /
  1000.0 /
  60 /
  60 /
  24;

var res = [
  {
    created_at: 1530489600,
    miner: "Kryptex",
    url: "https://www.kryptex.org/",
    earnings24: kryptex_data[kryptex_data.length - 1] / period,
    earnings1m: kryptex_data[kryptex_data.length - 1],
    total: kryptex_data.reduce((acc, b) => acc + b),
    logo: "kryptex-logo.png",
  },
  {
    created_at: 1530489600,
    miner: "Betterhash",
    url: "https://www.betterhash.net/",
    earnings24: betterhash_data[betterhash_data.length - 1] / period,
    earnings1m: betterhash_data[betterhash_data.length - 1],
    total: betterhash_data.reduce((acc, b) => acc + b),
    logo: "betterhash-icon.png",
  },
  {
    created_at: 1654034400,
    miner: "CudoOS",
    url: "https://www.cudominer.com/",
    earnings24: cudoos_data[cudoos_data.length - 1] / period,
    earnings1m: cudoos_data[cudoos_data.length - 1],
    total: cudoos_data.reduce((acc, b) => acc + b),
    logo: "cudominer-icon.png",
  },
  {
    created_at: 1547683200,
    miner: "NHM",
    url: "https://www.nicehash.com/",
    earnings24: nhml_data[nhml_data.length - 1] / period,
    earnings1m: nhml_data[nhml_data.length - 1],
    total: nhml_data.reduce((acc, b) => acc + b),
    logo: "nicehash-logo.png",
  },
  {
    created_at: 1551916800,
    miner: "CUDO",
    url: "https://www.cudominer.com/",
    earnings24: cudo_data[cudo_data.length - 1] / period,
    earnings1m: cudo_data[cudo_data.length - 1],
    total: cudo_data.reduce((acc, b) => acc + b),
    logo: "cudominer-icon.png",
  },
  {
    created_at: 1551916800,
    miner: "NHOS",
    url: "https://www.nicehash.com/nhos-mining",
    earnings24: nhos_data[nhos_data.length - 1] / period,
    earnings1m: nhos_data[nhos_data.length - 1],
    total: nhos_data.reduce((acc, b) => acc + b),
    logo: "nicehash-logo.png",
  },
  {
    created_at: 1551916800,
    miner: "NiceHash QuickMiner",
    url: "https://www.nicehash.com/nhos-mining",
    earnings24: nhqm_data[nhqm_data.length - 1] / period,
    earnings1m: nhqm_data[nhqm_data.length - 1],
    total: nhqm_data.reduce((acc, b) => acc + b),
    logo: "nicehash-logo.png",
  },
];

var winner = _.orderBy(res, "earnings24", "desc");
res = res.map((d) => {
  return {
    ...d,
    winner: winner && winner[0] && winner[0].earnings24 === d.earnings24,
    earnings24_p: +((1 - d.earnings24 / winner[0].earnings24) * 100).toFixed(0),
    earnings1m_p: +((1 - d.earnings1m / winner[0].earnings1m) * 100).toFixed(0),
    total_p: +((1 - d.total / winner[0].total) * 100).toFixed(0),
  };
});

const winnerTotal = _.orderBy(res, "total", "desc");
res = res.map((d) => {
  return {
    ...d,
    winnerTotal:
      winnerTotal && winnerTotal[0] && winnerTotal[0].total === d.total,
    total_p: +((1 - d.total / winnerTotal[0].total) * 100).toFixed(0),
  };
});

const out = {
  updates,
  betterhash: betterhash_data,
  kryptex: kryptex_data,
  nhml: nhml_data,
  cudo: cudo_data,
  cudoos: cudoos_data,
  nhos: nhos_data,
  nhqm: nhqm_data,
  res,
};

export default out;
