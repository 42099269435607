import styled from "styled-components";

export default function Footer() {
  return (
    <Wrapper>
      <InnerWrapper>
        <Link>Ⓒ 2021 Zekrox</Link>
        <Nav>
          {/* <LinkWrapper> */}
          {/* 	<Dot /> */}
          {/* 	<Link>Live Streaming Mining</Link> */}
          {/* </LinkWrapper> */}
          {/* <Link>FAQ</Link> */}
          {/* <Link>Contact</Link> */}
          <a style={{ textDecoration: "none" }} href="/tos.html">
            <Link>Terms of Service</Link>
          </a>
          <a style={{ textDecoration: "none" }} href="/pp.html">
            <Link>Privacy Policy</Link>
          </a>
        </Nav>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => ({
  width: "100%",
  background: "#fff",
}));

const InnerWrapper = styled.div((props) => ({
  margin: "0 auto",
  width: "calc(100% - 200px)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 72,
  maxWidth: 1440,
  "@media (max-width: 1200px)": {
    width: "calc(100% - 80px)",
  },
  "@media (max-width: 850px)": {
    flexDirection: "column-reverse",
    height: "auto",
    alignItems: "flex-start",
    paddingTop: "18px",
    paddingBottom: "24px",
  },
  "@media (max-width: 500px)": {
    width: "calc(100% - 40px)",
  },
}));

const Nav = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 850px)": {
    flexDirection: "column",
    height: "auto",
    alignItems: "flex-start",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
}));

const LinkWrapper = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Dot = styled.div((props) => ({
  width: 8,
  height: 8,
  borderRadius: 4,
  background: "#cc0000",
  marginRight: 6,
  marginBottom: -2,
}));

const Link = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 14,
  marginRight: 34,
  cursor: "pointer",
  transition: "0.2s",
  ":hover": {
    color: "#000",
  },
}));
