// import { Link } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Table from "../common/Table";
import Tabgroup from "../common/Tabs";
import DonationTabs from "../common/DonationTabs";
import Chart1 from "../common/Chart1";
import Chart2 from "../common/Chart2";
import ProgressBar from "../common/ProgressBar";
import miningDataNvidia from "../data.nvidia";
import miningDataAmd from "../data.amd";

var tableColumns = [
  {
    Header: "MINER SOLUTIONS",
    accessor: "miner",
    sortable: false,
  },
  {
    Header: "EARNINGS 24H",
    accessor: "earnings24",
    sortable: true,
  },
  {
    Header: "EARNINGS 35 LAST DAYS",
    accessor: "earnings1m",
    sortable: true,
  },
  {
    Header: "ALL TIME EARNINGS",
    accessor: "total",
    sortable: true,
  },
];

export default function App() {
  const types = ["NVIDIA", "AMD"];
  const [active, setActive] = useState(types[0]);

  const data = {
    AMD: miningDataAmd,
    NVIDIA: miningDataNvidia,
  };

  const miningData = data[active];

  const tableData = miningData.res.map((x) => ({
    ...x,
    // earnings24: parseFloat(x.earnings24).toFixed(5),
    earnings24: parseFloat(x.earnings24),
    earnings1m: parseFloat(x.earnings1m).toFixed(5),
    total: parseFloat(x.total).toFixed(5),
  }));

  const donationOptions = ["BTC", "ETH", "LTC"];
  const [activeDonation, setActiveDonation] = useState(donationOptions[0]);

  return (
    <Wrapper>
      <Header />
      <Body>
        <ButtonGroup>
          {types.map((type) => (
            <Tab
              key={type}
              active={active === type}
              selected={active}
              onClick={() => setActive(type)}
            >
              {type}
            </Tab>
          ))}
        </ButtonGroup>
        <Title secondary={true}>
          Find your most profitable mining software.
        </Title>
        <Text>
          Where to mine is a hobby project that compares profitablility of
          different mining software solutions.
        </Text>
        <Wrap>
          <Title>This months nVIDIA/AMD winning miner!</Title>
          <Button>
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={"https://www.nicehash.com/cpu-gpu-mining"}
            >
              <Span1>🏆</Span1> GET NiceHash QuickMiner<Span2>🏆</Span2>
            </a>
          </Button>
          {/* <Button> */}
          {/*   <a */}
          {/*     style={{ textDecoration: "none", color: "white" }} */}
          {/*     href={"https://www.kryptex.com/"} */}
          {/*   > */}
          {/*     <Span1>🏆</Span1> Download Kryptex Miner<Span2>🏆</Span2> */}
          {/*   </a> */}
          {/* </Button> */}
          {/* <Button> */}
          {/*   <a */}
          {/*     style={{ textDecoration: "none", color: "white" }} */}
          {/*     href={"https://www.cudominer.com/"} */}
          {/*   > */}
          {/*     <Span1>🏆</Span1> DOWNLOAD CUDO MINER <Span2>🏆</Span2> */}
          {/*   </a> */}
          {/* </Button> */}
        </Wrap>
        <Section1>
          {/* <First> */}
          {/*   <Title secondary={true}> */}
          {/*     Find your most profitable mining software. */}
          {/*   </Title> */}
          {/*   <Text> */}
          {/*     Where to mine is a hobby project that compares profitablility of */}
          {/*     different mining software solutions. */}
          {/*   </Text> */}
          {/*   <Wrap> */}
          {/*     <Title>This months winning miner!</Title> */}
          {/*     <Button> */}
          {/*       <a */}
          {/*         style={{ textDecoration: "none", color: "white" }} */}
          {/*         href={"https://www.nicehash.com/cpu-gpu-mining"} */}
          {/*       > */}
          {/*         <Span1>🏆</Span1> DOWNLOAD NICEHASH MINER <Span2>🏆</Span2> */}
          {/*       </a> */}
          {/*     </Button> */}
          {/*   </Wrap> */}
          {/* </First> */}
          {/* <Second> */}
          {/*   <a */}
          {/*     href={ */}
          {/*       "https://www.youtube.com/channel/UCvmtUoTZcpcDl8BKgk7VTlw/live" */}
          {/*     } */}
          {/*   > */}
          {/*     <Video src="../../video.png" /> */}
          {/*   </a> */}
          {/*   {/1* <Iframe *1/} */}
          {/*   {/1*   width="500" *1/} */}
          {/*   {/1*   height="300" *1/} */}
          {/*   {/1*   src="https://www.youtube.com/channel/UCvmtUoTZcpcDl8BKgk7VTlw/live" *1/} */}
          {/*   {/1*   title="YouTube video player" *1/} */}
          {/*   {/1*   frameborder="0" *1/} */}
          {/*   {/1*   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" *1/} */}
          {/*   {/1*   allowfullscreen *1/} */}
          {/*   {/1* ></Iframe> *1/} */}
          {/*   {/1* <Iframe2 *1/} */}
          {/*   {/1*   width="300" *1/} */}
          {/*   {/1*   height="300" *1/} */}
          {/*   {/1*   src="https://www.youtube.com/channel/UCvmtUoTZcpcDl8BKgk7VTlw/live" *1/} */}
          {/*   {/1*   title="YouTube video player" *1/} */}
          {/*   {/1*   frameborder="0" *1/} */}
          {/*   {/1*   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" *1/} */}
          {/*   {/1*   allowfullscreen *1/} */}
          {/*   {/1* ></Iframe2> *1/} */}
          {/* </Second> */}
        </Section1>
        <BannerWrapper>
          <Banner src="../../banner.png" />
        </BannerWrapper>
        <TableWrapper style={{ overflow: "auto" }}>
          <Table columns={tableColumns} data={tableData} />
        </TableWrapper>
        <BannerWrapper>
          <Banner src="../../banner.png" />
        </BannerWrapper>
        <div style={{ margin: "0 auto", maxWidth: "740px" }}>
          <Title secondary={true}>Monthly earnings</Title>
          <TextSmall>
            A graph of monthly earnings of all miner solutions since
          </TextSmall>
          <TextSmall>June 2021</TextSmall>
          <TableWrapper style={{ overflow: "hidden" }}>
            <Chart1 miningData={miningData} />
          </TableWrapper>
          <Title secondary={true}>Total Earnings</Title>
          <TextSmall>
            A graph of total earnings of all miner solutions since
          </TextSmall>
          <TextSmall>June 2021</TextSmall>
          <TableWrapper style={{ overflow: "hidden" }}>
            <Chart2 miningData={miningData} />
          </TableWrapper>
        </div>
        <BannerWrapper>
          <Banner src="../../banner.png" />
        </BannerWrapper>
        <div style={{ margin: "0 auto", maxWidth: "740px" }}>
          <Title secondary={true}>My Rigs</Title>
          <TextSmall>
            I use same rigs specs to test all 6 mining solutions.
          </TextSmall>
          <br />
          <Tabgroup active={active} />
          <Section2>
            <First>
              <Title id={"donate"} secondary={true}>
                Donate and Support
              </Title>
              <TextSmall>
                Like what you see? Help me mantain this project and support me
                with donation amount of your choice.
              </TextSmall>
              <ProgressBar value={15} />
              <Wrap3>
                <Wrap4>
                  <Text>Donations so far:</Text>
                  <BlueText>0.073 BTC</BlueText>
                </Wrap4>
                <Text>Goal: 0.500 BTC</Text>
              </Wrap3>
              <ButtonGroup>
                {donationOptions.map((type) => (
                  <DonationTab
                    key={type}
                    active={activeDonation === type}
                    selected={activeDonation}
                    onClick={() => setActiveDonation(type)}
                  >
                    {type}
                  </DonationTab>
                ))}
              </ButtonGroup>
              <DonationTabs active={activeDonation} />
              {/* <Wrap5> */}
              {/*   <SmallButton>BITCOIN</SmallButton> */}
              {/*   <SmallButton>ETHEREUM</SmallButton> */}
              {/*   <SmallButton>LITECOIN</SmallButton> */}
              {/* </Wrap5> */}
              {/* <Line> */}
              {/*   <Text>BTC:</Text> */}
              {/*   <Label>14GmpkcTuvG6m26xyTAyVP9twJHyEVEGy6</Label> */}
              {/* </Line> */}
              {/* <Line> */}
              {/*   <Text>LTC:</Text> */}
              {/*   <Label>LUwSnojLMUjDwaYqhy1wAax3PRfVdzjRoD</Label> */}
              {/* </Line> */}
              {/* <Line> */}
              {/*   <Text>ETH:</Text> */}
              {/*   <Label>0x7119233A7AF68D9Af155732FB30e10ee4407f489</Label> */}
              {/* </Line> */}
              {/* <Wrap6> */}
              {/*   <Text>Bitcoin (BTC) QR Code</Text> */}
              {/* </Wrap6> */}
              {/* <QRCode src="../../QRBTC.png" /> */}
            </First>
            <Second>
              <Title secondary={true}>Share</Title>
              <TextSmall>Share this project with your friends</TextSmall>
              <Wrap7>
                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwheretomine.com%2F&src=sdkpreparse">
                  <Link2>
                    <Svg>
                      <use xlinkHref={`../../facebook.svg#facebook`} />
                    </Svg>
                  </Link2>
                </a>
                <a href="https://twitter.com/intent/tweet?url=https://wheretomine.com">
                  <Link2>
                    <Svg>
                      <use xlinkHref={`../../twitter.svg#twitter`} />
                    </Svg>
                  </Link2>
                </a>
                <Link2 style={{ visibility: "hidden" }}>
                  <Svg>
                    <use xlinkHref={`../../instagram.svg#instagram`} />
                  </Svg>
                </Link2>
              </Wrap7>
              <br />
              <Title id={"contact"} secondary={true}>
                Contact
              </Title>
              <TextSmall>zekroxmine@gmail.com</TextSmall>
            </Second>
          </Section2>
        </div>
      </Body>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => ({}));

const Body = styled.div((props) => ({
  margin: "24px auto",
  width: "calc(100% - 200px)",
  maxWidth: 1440,
  "@media (max-width: 1200px)": {
    width: "calc(100% - 80px)",
  },
  "@media (max-width: 500px)": {
    width: "calc(100% - 40px)",
  },
}));

const Section1 = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  textAlign: "center",
  "@media (max-width: 1000px)": {
    flexDirection: "column",
  },
}));

const Section2 = styled.div((props) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: 72,
  "@media (max-width: 1000px)": {
    flexDirection: "column",
  },
}));

const First = styled.div((props) => ({
  width: "60%",
  paddingRight: "5%",
  "@media (max-width: 1000px)": {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    paddingRight: "0%",
    marginBottom: 40,
  },
}));

const Title = styled.div((props) => ({
  fontSize: props.secondary ? 20 : 24,
  fontWeight: props.secondary ? 700 : 900,
  fontStretch: "normal",
  lineHeight: 1.33,
  letterSpacing: "normal",
  color: "#212121",
  marginBottom: 20,
}));

const Text = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 16,
}));

const TextSmall = styled.div((props) => ({
  color: "#7a7a7a",
  fontSize: 14,
}));

const Wrap = styled.div((props) => ({
  width: "100%",
  height: "151px",
  margin: "0 auto",
  borderRadius: "75.5px",
  boxShadow: "0 4px 8px 0 rgba(0, 28, 108, 0.01)",
  backgroundColor: "rgba(13, 98, 254, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: 50,
  "@media (max-width: 600px)": {
    borderRadius: "30px",
    paddingLeft: 20,
    paddingRight: 20,
    width: "calc(100% - 40px)",
    height: "200px",
  },
}));

const Button = styled.div((props) => ({
  width: "337px",
  height: "48px",
  color: "#fff",
  margin: "15px 5px 0",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 4px 8px 0 rgba(0, 28, 108, 0.1)",
  backgroundColor: "#0d62fe",
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: 1.1,
  cursor: "pointer",
  transition: "0.2s ease-in-out",
  ":hover": {
    transform: "scale(1.05)",
  },
  "@media (max-width: 410px)": {
    fontWeight: 600,
    fontSize: 12,
    width: "auto",
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const Second = styled.div((props) => ({
  "@media (max-width: 1000px)": {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
  },
}));

const Span1 = styled.span((props) => ({
  marginRight: 10,
}));

const Span2 = styled.span((props) => ({
  marginLeft: 6,
}));

const Iframe = styled.iframe((props) => ({
  "@media (max-width: 600px)": {
    display: "none",
  },
}));

const Iframe2 = styled.iframe((props) => ({
  display: "none",
  "@media (max-width: 600px)": {
    margin: "0 auto",
    display: "block",
  },
}));

const BannerWrapper = styled.div((props) => ({
  margin: "72px auto",
  textAlign: "center",
}));

const TableWrapper = styled.div((props) => ({
  overflow: "scroll",
  maxWidth: "100%",
}));

const Banner = styled.img((props) => ({
  width: "60%",
  "@media (max-width: 1000px)": {
    width: "100%",
  },
}));

const Wrap2 = styled.div((props) => ({
  marginTop: 24,
}));

const Line = styled.div((props) => ({
  // width: 400,
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  height: 50,
  marginBottom: 1,
  paddingLeft: 24,
  "@media (max-width: 500px)": {
    width: "calc(100% - 24px)",
  },
}));

const Label = styled.div((props) => ({
  marginLeft: 2,
  fontSize: "14px",
}));

const Wrap3 = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 24,
  paddingBottom: 24,
}));

const Wrap4 = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
}));

const BlueText = styled.div((props) => ({
  color: "#0d62fe",
  marginLeft: 2,
  fontSize: 14,
}));

const Wrap5 = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: 340,
  marginTop: 24,
  marginBottom: 24,
}));

const SmallButton = styled.div((props) => ({
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 100,
  height: 34,
  cursor: "pointer",
  color: "#7a7a7a",
  fontWeight: 700,
  fontSize: 14,
}));

const Wrap6 = styled.div((props) => ({
  marginTop: 24,
  marginBottom: 24,
}));

const QRCode = styled.img((props) => ({
  width: 200,
}));

const Wrap7 = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: 180,
  marginTop: 24,
}));

const Link2 = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(13, 98, 254, 0.1)",
  borderRadius: "50%",
  width: 40,
  height: 40,
  cursor: "pointer",
}));

const Svg = styled.svg((props) => ({
  width: 18,
  minWidth: 18,
  height: 18,
  color: "#0d62fe",
  transition: "0.2s ease-in-out",
}));

const Video = styled.img((props) => ({
  width: "500px",
  "@media (max-width: 1000px)": {
    width: "100%",
  },
}));

const Tab = styled.button`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
    `}
  ${({ selected }) =>
    selected === "AMD" ? `border-color: red;` : `border-color: green;`}
`;

const DonationTab = styled.button`
  font-size: 20px;
  padding: 10px 56px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
    `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;
