// const apiUrl = "/api/v1/";
import _ from "lodash";

const updates = [
  "2021-06-01", //
  "2021-07-05",
  "2021-08-09",
  "2021-09-20",
  "2021-11-02", //
  "2021-12-06", //
  "2022-01-10", //
  "2022-02-11", //
  "2022-03-14", //
  "2022-06-07", //
  "2022-07-18", //
  "2022-08-16", //
  "2022-09-13", //
  "2022-11-14", //
  "2022-12-05", //
  "2023-01-03", //
  "2023-01-30", //
  "2023-03-13", //
  "2023-04-17", //
];

const nhml_data = [
  0.0, // NiceHash miner
  0.0027297,
  0.00435382,
  0.00370401,
  0.00414505,
  0.00314794,
  0.00338535,
  0.00251851,
  0.00238584,
  0.0057179,
  0.00248543,
  0.00215462,
  0.00241791,
  0.00132342,
  0.00041547,
  0.00058518,
  0.00048471,
  0.00080421,
  0.00051718,
];

const nhos_data = [
  0.0, // NHOS
  0.00384253,
  0.00499861,
  0.00489831,
  0.00358187,
  0.00325265,
  0.00341216,
  0.00248264,
  0.00236986,
  0.00615057,
  0.00254278,
  0.00240778,
  0.00241185,
  0.00110098,
  0.00035004,
  0.00041432,
  0.00040051,
  0.00073913,
  0.00059603,
];

const cudo_data = [
  0.0, // Cudo OS
  0.0033732,
  0.00462608,
  0.00316763,
  0.00400993,
  0.00304147,
  0.003233,
  0.00244401,
  0.00225366,
  0.00577034,
  0.00210549,
  0.00205661,
  0.00209381,
  0.00107607,
  0.00031706,
  0.00044221,
  0.00042876,
  0.00068491,
  0.00043127,
];

const cudoos_data = [
  0.0, // Cudo Miner
  0.00266483,
  0.0045134,
  0.0039584,
  0.00341801,
  0.00302395,
  0.00320816,
  0.0024343,
  0.00225105,
  0.00570197,
  0.00233717,
  0.00217863,
  0.00215934,
  0.00121159,
  0.00035944,
  0.00051217,
  0.0004897,
  0.00073489,
  0.00045526,
];

const betterhash_data = [
  0.0, // betterhash
  0.00282139,
  0.00378886,
  0.00394513,
  0.00346536,
  0.00297599,
  0.00297576,
  0.0022016,
  0.00204537,
  0.00538207,
  0.0020812,
  0.00200404,
  0.00198427,
  0.00113283,
  0.00035033,
  0.00042736,
  0.00039753,
  0.00049663,
  0.00048481,
];

const kryptex_data = [
  0.0, // kryptex
  0.00197847,
  0.00257596,
  0.00234741,
  0.0035463,
  0.00297935,
  0.00203055,
  0.00221034,
  0.00204663,
  0.00528807,
  0.00210945,
  0.00202105,
  0.00209176,
  0.00115418,
  0.00036518,
  0.00051629,
  0.00046062,
  0.00066932,
  0.00054817,
];

const period =
  (new Date(updates.slice(-1)[0]).getTime() -
    new Date(updates.slice(-2, -1)[0]).getTime()) /
  1000.0 /
  60 /
  60 /
  24;

var res = [
  {
    created_at: 1654034400,
    miner: "Kryptex",
    url: "https://www.kryptex.org/",
    earnings24: kryptex_data[kryptex_data.length - 1] / period,
    earnings1m: kryptex_data[kryptex_data.length - 1],
    total: kryptex_data.reduce((acc, b) => acc + b),
    logo: "kryptex-logo.png",
  },
  {
    created_at: 1654034400,
    miner: "Betterhash",
    url: "https://www.betterhash.net/",
    earnings24: betterhash_data[kryptex_data.length - 1] / period,
    earnings1m: betterhash_data[betterhash_data.length - 1],
    total: betterhash_data.reduce((acc, b) => acc + b),
    logo: "betterhash-icon.png",
  },
  {
    created_at: 1654034400,
    miner: "NHM",
    url: "https://www.nicehash.com/",
    earnings24: nhml_data[kryptex_data.length - 1] / period,
    earnings1m: nhml_data[nhml_data.length - 1],
    total: nhml_data.reduce((acc, b) => acc + b),
    logo: "nicehash-logo.png",
  },
  {
    created_at: 1654034400,
    miner: "CudoOS",
    url: "https://www.cudominer.com/",
    earnings24: cudoos_data[kryptex_data.length - 1] / period,
    earnings1m: cudoos_data[cudoos_data.length - 1],
    total: cudoos_data.reduce((acc, b) => acc + b),
    logo: "cudominer-icon.png",
  },
  {
    created_at: 1654034400,
    miner: "CUDO",
    url: "https://www.cudominer.com/",
    earnings24: cudo_data[kryptex_data.length - 1] / period,
    earnings1m: cudo_data[cudo_data.length - 1],
    total: cudo_data.reduce((acc, b) => acc + b),
    logo: "cudominer-icon.png",
  },
  {
    created_at: 1654034400,
    miner: "NHOS",
    url: "https://www.nicehash.com/nhos-mining",
    earnings24: nhos_data[kryptex_data.length - 1] / period,
    earnings1m: nhos_data[nhos_data.length - 1],
    total: nhos_data.reduce((acc, b) => acc + b),
    logo: "nicehash-logo.png",
  },
];

const winner = _.orderBy(res, "earnings24", "desc");
res = res.map((d) => {
  return {
    ...d,
    winner: winner && winner[0] && winner[0].earnings24 === d.earnings24,
    earnings24_p: +((1 - d.earnings24 / winner[0].earnings24) * 100).toFixed(0),
    earnings1m_p: +((1 - d.earnings1m / winner[0].earnings1m) * 100).toFixed(0),
    // total_p: +((1 - d.total / winner[0].total) * 100).toFixed(0),
  };
});

const winnerTotal = _.orderBy(res, "total", "desc");
res = res.map((d) => {
  return {
    ...d,
    winnerTotal:
      winnerTotal && winnerTotal[0] && winnerTotal[0].total === d.total,
    total_p: +((1 - d.total / winnerTotal[0].total) * 100).toFixed(0),
  };
});

// console.log("x", res);

const out = {
  updates,
  betterhash: betterhash_data,
  kryptex: kryptex_data,
  nhml: nhml_data,
  cudo: cudo_data,
  cudoos: cudoos_data,
  nhos: nhos_data,
  res,
};

export default out;
