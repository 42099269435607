import React, { useState } from "react";
import styled from "styled-components";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const Styles = styled.div`
  table {
    margin: 0 auto;
    border-spacing: 0;
    border: 0px solid #fff;
    background: #fff;
    text-align: left;

    thead {
      color: #7a7a7a;
      font-weight: 100;

      th {
        padding: 25px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 12px;

        &.active {
          color: blue;
        }

        &.sortable {
          cursor: pointer;
        }
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      border-right: 0px solid #fff;
      padding-left: 25px;
      font-size: 14px;

      :first-child {
        padding-right: 100px;
      }

      :last-child {
        border-right: 0;
      }
    }

    td {
      text-align: right;

      &.miner {
        text-align: left;

        img {
          display: inline-block;
          width: 32px;
          height: 32px;
          vertical-align: middle;
          margin-right: 8px;
        }
      }

      span.lower {
        color: red;
        margin-right: 4px;
      }
    }
  }
`;

export default class Table extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      orderBy: "earnings24",
      order: "desc",
    };
  }

  clickSort(key) {
    var { orderBy, order } = this.state;
    console.log("order by:", orderBy, key);
    if (key !== orderBy) this.setState({ orderBy: key, order: "desc" });
    else
      this.setState({ orderBy: key, order: order === "desc" ? "asc" : "desc" });
  }

  render() {
    var { data, columns } = this.props;
    var { orderBy, order } = this.state;
    data = _.orderBy(data, orderBy, order);

    return (
      <Styles>
        <table>
          <thead>
            <tr>
              {columns.map((c) => {
                return (
                  <th
                    className={
                      (c.sortable ? "sortable" : "") +
                      " " +
                      (c.accessor === orderBy ? "active " : "")
                    }
                    onClick={
                      c.sortable ? this.clickSort.bind(this, c.accessor) : null
                    }
                  >
                    {c.sortable ? <FontAwesomeIcon icon={faSort} /> : ""}{" "}
                    {c.Header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              console.log(d);
              return (
                <tr>
                  {columns.map((c) => {
                    console.log(c);
                    if (c.accessor === "miner")
                      return (
                        <td className="miner">
                          <img src={d.logo} alt="" /> {d[c.accessor]}
                        </td>
                      );

                    return (
                      <td>
                        {" "}
                        {(c.accessor === "total" && d.winnerTotal) ||
                        (c.accessor !== "total" && d.winner) ? (
                          <span>🏆</span>
                        ) : (
                          <span className="lower">
                            - {d[c.accessor + "_p"]}%
                          </span>
                        )}{" "}
                        {c.accessor === "earnings24"
                          ? parseFloat(d[c.accessor]).toFixed(5)
                          : d[c.accessor]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Styles>
    );
  }
}
